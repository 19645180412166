import React from 'react'
import { initSentry, MatchTracker } from '@frontend/sportable'
import './index.scss'
import { env } from '@frontend/sportable'
import { createRoot } from 'react-dom/client'

const version = APP_VERSION

console.log('Match Tracker Version:', version)

initSentry(
  env.environment,
  version,
  'https://0ddfb6845d1c46efa3d7e0f965577158@o241876.ingest.sentry.io/5589770'
)

const initialState = {
  version: {
    ui: `${version}`,
    lib: `${version}`,
    uiType: {
      name: 'Match Tracker',
      value: 'matchTracker'
    },
    app: {
      Env: env.environment.ENV,
      Deployment: env.environment.deployment
    }
  }
}

const ConnectedApp = () => {
  return MatchTracker(initialState)
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<ConnectedApp />)
